<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <app-list-header
      :filtrele="filtrele"
      :toplamUrun="toplamUrun"
      @itemView="itemView = $event"
      @mqShallShowLeftSidebar="mqShallShowLeftSidebar = $event"
      @handlerStatu="handlerStatu($event)"
      @handlerNewUrun="handlerNewUrun"
      @handlerUrunSiralama="handlerUrunSiralama"
    />
    <!-- Prodcuts -->
    <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <section :class="itemView">
        <b-card v-for="urun in urunler" :key="urun._id" class="ecommerce-card rounded-0" no-body>
          <div class="item-img text-center">
            <b-link :to="{ name: 'urun-bilgisi', params: { k_no: urun.k_no } }">
              <b-img :alt="`${urun.icerik['tr'].urun_adi}`" fluid :src="imageFile(urun.gorseller)" />
            </b-link>
            <div class="item-price">
              <h6 class="text-white">Y - {{ urun.tekil_fiyat.yetiskin.fiyat | cur }} {{ urun.kur }}</h6>
              <h6 class="text-white">Ç - {{ urun.tekil_fiyat.cocuk.fiyat | cur }} {{ urun.kur }}</h6>
              <h6 class="text-white">B - {{ urun.tekil_fiyat.bebek.fiyat | cur }} {{ urun.kur }}</h6>
            </div>
          </div>

          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div class="item-rating">
                <ul class="unstyled-list list-inline">
                  <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                    <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{ 'fill-current': star <= urun.rating }, star <= urun.rating ? 'text-warning' : 'text-muted']"
                    />
                  </li>
                </ul>
              </div>
              <b-media no-body class="mt-0">
                <b-media-aside v-for="(lang, i) in urun.icerik" :key="i" class="m-0 mr-25">
                  <b-avatar rounded class="rounded-0" variant="light-success" size="22"> {{ i }} </b-avatar>
                </b-media-aside>
              </b-media>
            </div>
            <h6 class="item-name">
              <b-link class="text-body" :to="{ name: 'urun-bilgisi', params: { k_no: urun.k_no } }">
                {{ urun.icerik['tr'].urun_adi }}
              </b-link>
              <b-card-text class="item-company">
                By
                <b-link class="ml-25">
                  {{ urun.save_user }}
                </b-link>
              </b-card-text>
            </h6>
            <b-card-text class="item-description">
              {{ urun.icerik['tr'].on_aciklama | str_limit(40) }}
            </b-card-text>
          </b-card-body>

          <div class="item-options text-center">
            <b-button
              :variant="urun.statu == 1 ? 'success' : urun.statu == 2 ? 'danger' : 'warning'"
              squared
              tag="a"
              class="btn-cart"
              :to="{ name: 'urun-bilgisi', params: { k_no: urun.k_no } }"
            >
              <feather-icon icon="FilmIcon" class="mr-50" />
              <span>{{ urun.statu == 1 ? 'Yayında' : urun.statu == 2 ? 'Yayında Değil' : 'Taslak' }}</span>
            </b-button>
            <b-button
              variant="primary"
              squared
              tag="a"
              class="btn-cart"
              :to="{ name: 'urun-bilgisi', params: { k_no: urun.k_no } }"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Güncelle</span>
            </b-button>
          </div>
        </b-card>
      </section>
    </b-overlay>

    <!-- Sayfalama -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <v-select
          v-model="filtrele.page_size"
          :options="perPageOptions"
          :clearable="false"
          class="invoice-filter-select"
          style="min-width: 200px"
          @input="(value) => handlerPageSizeChange(value)"
        />
      </div>
      <app-sayfalama :filtrele="filtrele" :toplamUrun="toplamUrun" @handlerPageChange="handlerPageChange($event)" />
    </div>
    <!-- Sol Bar -->
    <portal to="content-renderer-sidebar-detached-left">
      <!-- <vue-position-sticky> -->
      <app-sol-filtre
        @filtreUygula="hadnlerGetAllData"
        :filtrele="filtrele"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
      <!-- </vue-position-sticky> -->
    </portal>
  </div>
</template>

<script>
import AppListHeader from './component/listHeader.vue';
import AppSolFiltre from './component/solFlitre.vue';
import AppSayfalama from './component/sayfalama.vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppListHeader,
    AppSolFiltre,
    AppSayfalama,
    vSelect,
  },
  data: () => ({
    show: false,
    toplamUrun: 0,
    mqShallShowLeftSidebar: false,
    perPageOptions: [9, 18, 36, 72],
    filtrele: {
      statu: { text: 'Tümü', value: null },
      arama: null,
      kategori: [],
      bolge: [],
      begeni: null,
      page: 1,
      page_size: 9,
    },
    itemView: 'grid-view',
    filterOptions: {
      kategoriler: [],
      bolgeler: [],
      ratings: [
        { rating: 4, count: 160 },
        { rating: 3, count: 176 },
        { rating: 2, count: 291 },
        { rating: 1, count: 190 },
      ],
    },
    urunler: [],
  }),
  computed: {
    ...mapGetters(['getUrunler', 'getKategoriler', 'getBolgeler']),
    currentBreakPoint() {
      () => this.$store.getters['app/currentBreakPoint'];
    },
    imageFile() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

      return (img) => {
        if (img.length > 0) {
          const selectImg = img.find((x) => x.kapak == true);
          if (selectImg != undefined) {
            return `${base_url}uploads/urunler/large/${selectImg.gorsel}`;
          } else {
            return `${base_url}uploads/urunler/large/${img[0].gorsel}`;
          }
        } else {
          return `${base_url}uploads/no/no-image.jpeg`;
        }
        // console.log(img);
      };
    },
  },
  created() {
    this.handlarModuller();
    this.hadnlerGetAllData();
  },
  methods: {
    async handlarModuller() {
      this.show = true;
      await this.$store.dispatch('kategoriListele');
      await this.$store.dispatch('bolgeListele');
      this.kategoriler = [];
      this.bolgeler = [];
      const kategoriler = await this.getKategoriler;
      const bolgeler = await this.getBolgeler;

      kategoriler.forEach((el) => {
        if (el.kategori_k_no == null) {
          this.filterOptions.kategoriler.push({ key: el.k_no, k_no: el.k_no, name: el.icerik['tr'].baslik, subOptions: [] });
        }
      });

      kategoriler.forEach((el) => {
        if (el.kategori_k_no != null) {
          let index = this.filterOptions.kategoriler.findIndex((x) => x.k_no == el.kategori_k_no);
          if (index != -1) {
            this.filterOptions.kategoriler[index].subOptions.push({ key: el.k_no, k_no: el.k_no, name: el.icerik['tr'].baslik });
          }
        }
      });

      bolgeler.forEach((el) => {
        if (el.bolge_k_no == null) {
          this.filterOptions.bolgeler.push({ key: el.k_no, k_no: el.k_no, name: el.baslik, subOptions: [] });
        }
      });

      bolgeler.forEach((el) => {
        if (el.bolge_k_no != null) {
          let index = this.filterOptions.bolgeler.findIndex((x) => x.k_no == el.bolge_k_no);
          this.filterOptions.bolgeler[index].subOptions.push({ key: el.k_no, k_no: el.k_no, name: el.baslik });
        }
      });

      this.show = false;
    },
    async hadnlerGetAllData() {
      this.show = true;
      const params = this.getRequestParams(
        this.filtrele.arama,
        this.filtrele.statu,
        this.filtrele.kategori,
        this.filtrele.bolge,
        this.filtrele.page,
        this.filtrele.page_size
      );
      const urunler = await this.$store.dispatch('urunListele', params);
      if (urunler.data.success == true) {
        this.toplamUrun = urunler.data.count;
        this.urunler = this.getUrunler;
      }
      this.show = false;
    },
    getRequestParams(arama, statu, kategori, bolge, page, page_size) {
      let params = {};
      if (arama) {
        params['arama'] = arama;
      }
      if (statu) {
        params['statu'] = statu;
      }
      if (kategori) {
        params['kategori'] = kategori;
      }
      if (bolge) {
        params['bolge'] = bolge;
      }
      if (page) {
        params['page'] = page;
      }
      if (page_size) {
        params['page_size'] = page_size;
      }
      return params;
    },
    handlerPageChange(event) {
      this.filtrele.page = event;
      this.hadnlerGetAllData();
    },
    handlerPageSizeChange(event) {
      this.filtrele.page_size = event;
      this.hadnlerGetAllData();
    },
    handlerStatu(val) {
      this.filtrele.statu = val;
      this.hadnlerGetAllData();
    },
    handlerNewUrun() {
      this.$router.push({ name: 'urun-ekle' });
    },

    handlerUrunSiralama() {
      this.$router.push({ name: 'urun-siralama' });
    },
  },
  watch: {
    currentBreakPoint: function (val, oldVal) {
      if (oldVal === 'md' && val === 'lg') mqShallShowLeftSidebar = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.positions {
  position: absolute;
  right: 2px;
}
.item-img {
  position: relative;
  .item-price {
    position: absolute;
    background: rgba(194, 1, 1, 0.673);
    padding: 15px 10px;
    bottom: 0px;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
