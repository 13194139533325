<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="filtrele.page"
          :total-rows="toplamUrun"
          :per-page="filtrele.page_size"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="$emit('handlerPageChange', $event)"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  props: {
    filtrele: {
      type: Object,
      required: true,
    },
    toplamUrun: [Number, String],
  },
};
</script>

<style lang="scss" scoped></style>
