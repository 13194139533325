<template>
  <section id="ecommerce-header">
    <div class="row">
      <div class="col-sm-12">
        <div class="ecommerce-header-items">
          <div class="result-toggler">
            <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="$emit('mqShallShowLeftSidebar', true)" />
          </div>
          <div class="view-options d-flex">
            <div class="search-results my-1 mr-1">{{ toplamUrun }} sonuç bulundu</div>
            <!-- Sort Button -->
            <b-dropdown toggle-class="rounded-0" :text="filtrele.statu.text" right variant="outline-primary">
              <b-dropdown-item
                squared
                toggle-class="rounded-0"
                v-for="status in statuOptions"
                :key="status.value"
                @click="$emit('handlerStatu', status)"
              >
                {{ status.text }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Item View Radio Button Group  -->
            <b-form-radio-group
              v-model="itemView"
              class="ml-1 list item-view-radio-group mr-1"
              buttons
              size="sm"
              button-variant="outline-primary"
              @change="handlerChangeItemView"
            >
              <b-form-radio class="rounded-0" v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                <feather-icon :icon="option.icon" size="18" />
              </b-form-radio>
            </b-form-radio-group>

            <b-button squared variant="warning" @click="$emit('handlerUrunSiralama')">
              <feather-icon icon="ListIcon" size="18" />
              Ürün Sıralaması Düzenle
            </b-button>

            <b-button squared variant="primary" @click="$emit('handlerNewUrun')">
              <feather-icon icon="PlusSquareIcon" size="18" />
              Ürün Ekle
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    toplamUrun: [Number, String],
    filtrele: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemView: 'grid-view',
    statuOptions: [
      { text: 'Tümü', value: null },
      { text: 'Yayında', value: 1 },
      { text: 'Yayın Dışı', value: 2 },
      { text: 'Taslak', value: 3 },
    ],
    itemViewOptions: [
      { icon: 'GridIcon', value: 'grid-view' },
      { icon: 'ListIcon', value: 'list-view' },
    ],
  }),
  methods: {
    updateMqShallShowLeftSidebar(statu) {
      this.$emit('mqShallShowLeftSidebar', statu);
    },
    handlerChangeItemView(event) {
      console.log(event);
      this.$emit('itemView', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
